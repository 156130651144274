import { Chart, ChartConfiguration } from "chart.js"
import { Controller } from "@hotwired/stimulus"
import { template } from "lodash"
import { hide, show } from "../utils"
import annotationPlugin from "chartjs-plugin-annotation"
import customYAxisTicks from "../utils/chart/plugins/custom_y_axis_ticks"

Chart.register(annotationPlugin)

/*
 This is a wrapper around chart.js that strives to be as small as possible. It is designed to work in conjunction
 with the Charts::ChartComponent view component, which is responsible for supplying the bulk of the necessary
 chart.js configuration. Only those things that _must_ happen in javascript are done here — wiring up event handlers,
 or formatting callbacks.

 Events are converted into stimulus events that can be registered to stimulus actions without, hopefully, needing to
 modify this code at all.
 */

// Connects to data-controller="chart-component"
export default class ChartComponentController extends Controller {
  /**
   * {{}} for eval
   * {{= }} for interpolation
   */
  static templateConfig = {
    evaluate: /\{\{([\s\S]+?)\}\}/g,
    interpolate: /\{\{=([\s\S]+?)\}\}/g,
    escape: /\{\{-([\s\S]+?)\}\}/g,
  }

  static targets = [
    "canvas",
    "config",
    "tooltip",
    "tooltipBodyTemplate",
    "tooltipContent",
    "tooltipFooterTemplate",
    "tooltipHeaderTemplate",
    "wrapper",
    "yAxisTemplate",
  ]
  canvasTarget: HTMLCanvasElement
  configTarget: HTMLScriptElement
  tooltipBodyTemplateTarget: HTMLTemplateElement
  tooltipContentTarget: HTMLDivElement
  tooltipFooterTemplateTarget: HTMLTemplateElement
  tooltipHeaderTemplateTarget: HTMLTemplateElement
  tooltipTarget: HTMLDivElement
  wrapperTarget: HTMLElement

  hasTooltipBodyTemplateTarget: boolean
  hasTooltipHeaderTemplateTarget: boolean
  hasTooltipFooterTemplateTarget: boolean
  hasTooltipTarget: boolean

  static values = { currency: String }
  currencyValue: string

  chart: Chart
  tooltip: HTMLDivElement
  tooltipHeaderTemplate: (data: any) => string
  tooltipBodyTemplate: (data: any) => string
  tooltipFooterTemplate: (data: any) => string
  tooltipOffsets: { x: number; y: number }
  drawBackgroundLabel: (chart: Chart, startLabel: string, labelText: string) => void

  onClick(_event, elements) {
    this.dispatch("click", {
      detail: { elements, datapoints: this.extractDatapoints(elements) },
    })
  }

  onHover(_event, elements) {
    this.dispatch("hover", {
      detail: { elements, datapoints: this.extractDatapoints(elements) },
    })
  }

  extractDatapoints(elements) {
    return elements.map(({ element }) => {
      return { ...element.$context.raw, _context: element.$context }
    })
  }

  withCurrency(value) {
    if (!this.currencyValue) {
      return value
    }

    return value.toLocaleString("en-US", {
      style: "currency",
      currency: this.currencyValue,
      maximumFractionDigits: 0,
    })
  }

  tooltipHandler({ tooltip, chart: { canvas } }) {
    if (tooltip.opacity === 0) {
      return hide(this.tooltipTarget)
    }

    this.tooltipContentTarget.innerHTML = this.renderTooltip(tooltip)
    this.positionTooltip(tooltip, canvas)

    show(this.tooltipTarget)
  }

  // returns the sum of the datapoint values with the given key or the parsed x/y values
  // note that only the datapoints passed to the tooltip are available, which is based on the interaction mode
  // nearest, which renders a tooltip for just a single chunk of a stacked bar, for example, only gets the one
  // datapoint, rather than all in the stack
  sumOf(datapoints, key) {
    const values = datapoints.map((datapoint) => {
      if (key === "parsed.x") {
        return datapoint.parsed.x
      } else if (key === "parsed.y") {
        return datapoint.parsed.y
      } else {
        return datapoint.raw[key]
      }
    })
    return values.reduce((acc, val) => acc + val, 0)
  }

  renderTooltip(tooltip) {
    const parts = [
      this.renderTooltipHeader(tooltip),
      this.renderTooltipBody(tooltip),
      this.renderTooltipFooter(tooltip),
    ]
    return parts.join("")
  }

  commonTooltipVars(tooltip) {
    return {
      _chart: tooltip.chart,
      _currency: this.currencyValue,
      sumOf: this.sumOf.bind(this, tooltip.dataPoints),
      withCurrency: this.withCurrency.bind(this),
    }
  }

  renderTooltipHeader(tooltip) {
    if (!this.tooltipHeaderTemplate) {
      return
    }
    return this.tooltipHeaderTemplate({
      ...this.commonTooltipVars(tooltip),
      title: tooltip.title,
    })
  }

  renderTooltipBody(tooltip) {
    if (!this.tooltipBodyTemplate) {
      return
    }
    // some interaction modes (such as interacting with vertical lines) might return multiple data points
    // in these cases, the template is rendered for each one and the results are joined
    return tooltip.dataPoints
      .map((datapoint, idx) => {
        return this.tooltipBodyTemplate({
          // expand the raw dataset values for use in the template
          ...datapoint.raw,
          ...this.commonTooltipVars(tooltip),
          _label: datapoint.label,
          _value: datapoint.formattedValue,
          _datasetLabel: datapoint.dataset.label,
          _color: tooltip.labelColors[idx].backgroundColor,
        })
      })
      .toReversed()
      .join("")
  }

  renderTooltipFooter(tooltip) {
    if (!this.tooltipFooterTemplate) {
      return
    }
    return this.tooltipFooterTemplate({
      ...this.commonTooltipVars(tooltip),
      title: tooltip.title,
    })
  }

  positionTooltip(tooltip, canvas) {
    const { left: xOffset, top: yOffset } = this.canvasTarget.getBoundingClientRect()
    this.tooltipTarget.style.left = `${xOffset + tooltip.caretX}px`
    this.tooltipTarget.style.top = `${yOffset + tooltip.caretY}px`
  }

  parseConfig() {
    const config = JSON.parse(this.configTarget.text)

    config.options.onClick = this.onClick.bind(this)
    config.options.onHover = this.onHover.bind(this)

    if (typeof config?.options?.scales?.y?.ticks === "object") {
      config.options.scales.y.ticks.callback = this.withCurrency.bind(this)
    }

    if (this.hasTooltipTarget) {
      config.options.plugins.tooltip.external = this.tooltipHandler.bind(this)
    }

    return config
  }

  connect() {
    if (this.hasTooltipTarget) {
      if (this.hasTooltipHeaderTemplateTarget) {
        this.tooltipHeaderTemplate = template(
          this.tooltipHeaderTemplateTarget.innerHTML,
          ChartComponentController.templateConfig,
        )
      }
      if (this.hasTooltipBodyTemplateTarget) {
        this.tooltipBodyTemplate = template(
          this.tooltipBodyTemplateTarget.innerHTML,
          ChartComponentController.templateConfig,
        )
      }
      if (this.hasTooltipFooterTemplateTarget) {
        this.tooltipFooterTemplate = template(
          this.tooltipFooterTemplateTarget.innerHTML,
          ChartComponentController.templateConfig,
        )
      }
    }

    // adds a background image or color to a data segment in a chart
    // with the added option to have a label added to the background
    // which gets put at the start or end of the background
    const backgroundPlugin = {
      id: "backgroundFromX",
      beforeDraw: (chart, args, options) => {
        const pluginOptions = chart.options.plugins.backgroundFromX
        if (!pluginOptions?.backgroundStart || !pluginOptions?.backgroundEnd) return

        const {
          ctx,
          scales: { x: xAxis },
          chartArea,
        } = chart
        const {
          backgroundStart,
          backgroundEnd,
          backgroundType,
          backgroundImage,
          backgroundColor,
          backgroundLabel,
          backgroundLabelFont,
          backgroundLabelColor,
          backgroundLabelPosition,
        } = pluginOptions

        // Find the indices of the start and end labels
        // This calculates the width of a single segment by finding the distance between the first two labels
        const startIndex = chart.data.labels.indexOf(backgroundStart)
        const endIndex = chart.data.labels.indexOf(backgroundEnd)
        if (startIndex === -1 || endIndex === -1) return

        // Calculate segment width
        const segmentWidth = xAxis.getPixelForValue(chart.data.labels[1]) - xAxis.getPixelForValue(chart.data.labels[0])

        // Calculate pixel positions to cover entire segments
        // Note that we subtract half a segment's width from the start and add half to the end
        // This ensures the background covers from the start of the first segment to the end of the last segment
        const startPixel = Math.max(
          chartArea.left,
          xAxis.getPixelForValue(chart.data.labels[startIndex]) - segmentWidth / 2,
        )
        const endPixel = Math.min(
          chartArea.right,
          xAxis.getPixelForValue(chart.data.labels[endIndex]) + segmentWidth / 2,
        )

        if (startPixel >= endPixel) return

        ctx.save()
        ctx.beginPath()
        // We use the calculated start and end pixels to define the background area
        ctx.rect(startPixel, chartArea.top, endPixel - startPixel, chartArea.height)
        ctx.clip()

        if (backgroundType === "image" && backgroundImage) {
          if (!chart.backgroundImage) {
            chart.backgroundImage = new Image()
            chart.backgroundImage.src = backgroundImage
            chart.backgroundImage.onload = () => chart.update("none")
          } else if (chart.backgroundImage.complete) {
            const pattern = ctx.createPattern(chart.backgroundImage, "repeat")
            if (pattern) {
              ctx.fillStyle = pattern
              // We use the calculated start and end pixels to draw the background
              ctx.fillRect(startPixel, chartArea.top, endPixel - startPixel, chartArea.height)
            }
          }
        } else {
          ctx.fillStyle = backgroundColor || "rgba(0,0,0,0.1)"
          ctx.fillRect(startPixel, chartArea.top, endPixel - startPixel, chartArea.height)
        }

        ctx.restore()

        // Draw background label
        if (backgroundLabel) {
          ctx.save()
          ctx.font = backgroundLabelFont || "bold 10px sans-serif"
          ctx.fillStyle = backgroundLabelColor || "#292929"
          ctx.textAlign = "left"
          ctx.textBaseline = "top"

          // Label position is based on the calculated start and end pixels
          const labelX = backgroundLabelPosition === "end" ? endPixel : startPixel
          const labelY = chartArea.top - 16

          ctx.fillText(backgroundLabel, labelX, labelY)
          ctx.restore()
        }
      },
    }

    // we may optionally add a light background color to the data segments when these are hovered
    // this is meant to use when a segment can trigger an event
    let hoverValue = undefined // joisting this here
    const hoverSegment = {
      id: "hoverSegment",
      beforeDatasetsDraw(chart, args, pluginOptions) {
        const options = chart.options.plugins.hoverSegment
        if (!options?.enabled || this.hoverValue === undefined) return

        const {
          ctx,
          chartArea: { top, height },
          scales: { x },
        } = chart
        const segment = chart.chartArea.width / (x.max + 1)
        const xPos = x.getPixelForValue(this.hoverValue) - segment / 2

        ctx.save()
        ctx.fillStyle = options.color || "rgba(220, 223, 227, .25)"
        ctx.fillRect(xPos, top, segment, height)
        ctx.restore()
      },
      afterEvent(chart, args) {
        const options = chart.options.plugins.hoverSegment
        if (!options?.enabled) return

        const oldValue = this.hoverValue
        this.hoverValue = args.inChartArea ? chart.scales.x.getValueForPixel(args.event.x) : undefined

        if (oldValue !== this.hoverValue) {
          args.changed = true
        }
      },
    }

    const config = this.parseConfig()

    // Merges all plugins into the config
    const pluginMap = new Map(config.plugins?.map((p) => [p.id, p]) || [])
    ;[backgroundPlugin, hoverSegment, customYAxisTicks].forEach((newPlugin) => {
      if (newPlugin.id === "hoverSegment" && !config.options?.plugins?.hoverSegment?.enabled) {
        return
      }
      const existingPlugin = pluginMap.get(newPlugin.id)
      if (existingPlugin) {
        Object.assign(existingPlugin, newPlugin)
      } else {
        pluginMap.set(newPlugin.id, newPlugin)
      }
    })
    config.plugins = Array.from(pluginMap.values())

    this.chart = new Chart(this.canvasTarget, config as ChartConfiguration)
  }

  disconnect() {
    this.chart.destroy()
  }

  rerender() {
    this.chart.destroy()
    const config = this.parseConfig()
    config.options.animation = false
    this.chart = new Chart(this.canvasTarget, config)
  }
}
