import { Controller } from "@hotwired/stimulus"
import { hide, isEmpty, show } from "../utils"

// Connects to data-controller="highlight-on-document"
export default class extends Controller {
  static targets = ["inputField", "loader"]
  static values = {
    documentType: String,
    boundingPolys: Array,
    triggerDelayedHighlight: String,
    inputHighlightClasses: {
      type: String,
      default: "canopy-card-flat py-4",
    },
  }
  static polyObject = {
    fieldName: String,
    page: Number,
    normalizedVertices: Array,
  }

  documentTypeValue: string
  triggerDelayedHighlightValue: string
  boundingPolysValue: Array<polyObject>
  inputFieldTargets: Array<HTMLDivElement>
  loaderTarget: HTMLDivElement
  inputHighlightClassesValue: string

  connect() {
    if (this.documentTypeValue === "pdf") {
      this.setupPdfjsEventListener()
      this.setupFieldListeners()
    }
    if (this.documentTypeValue === "image") {
      const image = document.getElementById("image-viewer")
      // Ensure the image has a valid src attribute
      if (image && image.src) {
        // Check if the image is already loaded
        if (image.complete) {
          this.setupImagePolygons()
          this.setupFieldListeners()
        } else {
          // Add an event listener for when the image loads
          image.addEventListener("load", () => {
            this.setupImagePolygons()
            this.setupFieldListeners()
          })

          // Optionally, handle the case where the image fails to load
          image.addEventListener("error", () => {
            console.error("Failed to load image.")
          })
        }
      }
    }

    if (this.triggerDelayedHighlightValue) {
      this.showLoader()
      setTimeout(() => {
        this.hideLoader()
        this.handleFieldClick(this.triggerDelayedHighlightValue)
      }, 1250)
    }
  }

  showLoader() {
    show(this.loaderTarget)
  }

  hideLoader() {
    hide(this.loaderTarget)
  }

  setupImagePolygons() {
    const image = document.getElementById("overlay")
    const imageWidth = image.clientWidth
    const imageHeight = image.clientHeight

    this.boundingPolysValue.forEach((poly) => {
      if (!isEmpty(poly.page)) {
        const boundingPoly = poly.normalizedVertices
        const polygonContainer = document.createElement("div")
        polygonContainer.className = `polygon-container ${poly.fieldName}`
        polygonContainer.style.position = "absolute"
        polygonContainer.style.left = `${boundingPoly[0].x * imageWidth}px`
        polygonContainer.style.top = `${boundingPoly[0].y * imageHeight}px`
        polygonContainer.style.width = `${(boundingPoly[1].x - boundingPoly[0].x) * imageWidth + 10}px`
        polygonContainer.style.height = `${(boundingPoly[2].y - boundingPoly[0].y) * imageHeight + 10}px`
        polygonContainer.style.zIndex = "5"

        image.appendChild(polygonContainer)

        polygonContainer.addEventListener("mouseenter", () => {
          polygonContainer.classList.add("hovered")
        })

        polygonContainer.addEventListener("mouseleave", () => {
          polygonContainer.classList.remove("hovered")
        })
      }
    })
  }

  // Setup listeners for field click events
  setupFieldListeners() {
    const inputFields =
      this.inputFieldTargets.length > 0
        ? this.inputFieldTargets
        : document.querySelectorAll('button[data-highlight-on-document-target="inputField"]')

    inputFields.forEach((inputField) => {
      if (!inputField.dataset.attachedHighlightOnDocumentListener) {
        inputField.addEventListener("click", this.handleFieldClick.bind(this, inputField.id))
        inputField.dataset.attachedHighlightOnDocumentListener = true
      }
    })
  }

  // Handle click events on input fields
  handleFieldClick(fieldId) {
    const fieldMapping = Object.values(this.boundingPolysValue).find((mapping) => mapping.fieldName === fieldId)

    this.removeAllFieldFocus()
    if (fieldMapping) {
      this.focusField(fieldId)
      this.highlightText(fieldMapping)
      this.scrollToText(fieldMapping)
    }
  }

  removeAllFieldFocus() {
    const inputFields =
      this.inputFieldTargets.length > 0
        ? this.inputFieldTargets
        : document.querySelectorAll('button[data-highlight-on-document-target="inputField"]')

    inputFields.forEach((inputField) => {
      const field = this.getFocusField(inputField.id)
      this.applyFocus(field, false)
    })
  }

  applyFocus(field, apply) {
    const classes = (this.inputHighlightClassesValue || "").split(" ")
    if (apply) {
      classes.forEach((css) => {
        field.classList.add(css)
      })
    } else {
      classes.forEach((css) => {
        field.classList.remove(css)
      })
    }
  }

  // Apply focus highlighting to the input field
  focusField(fieldId) {
    const field = this.getFocusField(fieldId)
    this.applyFocus(field, true)
  }

  // Highlight text in the document
  highlightText(fieldMapping) {
    let polygonContainer
    if (this.documentTypeValue === "pdf") {
      const iframe = document.getElementById("pdf-viewer")
      // some pages may start from a thumbnail view of all documents rather than a single document, so there's no guarantee
      // the PDF viewer will be on the page at connect
      if (!iframe) return

      const iframeWindow = iframe.contentWindow
      const pdfViewerApplication = iframeWindow.PDFViewerApplication
      if (!pdfViewerApplication) return

      this.removeAllPdfHighlights(pdfViewerApplication)
      const page = pdfViewerApplication.pdfViewer.getPageView(fieldMapping.page)
      if (!isEmpty(page)) {
        polygonContainer = page.div.querySelector(`.polygon-container.${fieldMapping.fieldName}`)
      }
    } else {
      const image = document.getElementById("overlay")
      if (!image) return

      this.removeAllImageHighlights(image)
      polygonContainer = image.querySelector(`.polygon-container.${fieldMapping.fieldName}`)
    }

    if (polygonContainer) {
      polygonContainer.style.backgroundColor = "rgb(168, 85, 247, 0.2)"
    }
  }

  removeAllPdfHighlights(pdfViewerApplication) {
    for (let pageNumber = 0; pageNumber < pdfViewerApplication.pagesCount; pageNumber++) {
      let page = pdfViewerApplication.pdfViewer.getPageView(pageNumber)
      page.div.querySelectorAll(".polygon-container").forEach((polygon) => {
        polygon.style.backgroundColor = ""
      })
    }
  }

  removeAllImageHighlights(image) {
    image.querySelectorAll(".polygon-container").forEach((polygon) => {
      polygon.style.backgroundColor = ""
    })
  }

  // Scroll to the relevant text in the document
  scrollToText({ page, fieldName }) {
    if (this.documentTypeValue === "pdf") {
      const iframe = document.getElementById("pdf-viewer")
      if (!iframe) return

      const iframeWindow = iframe.contentWindow
      const pdfViewerApplication = iframeWindow.PDFViewerApplication
      if (!pdfViewerApplication) return

      const pdfPage = pdfViewerApplication.pdfViewer.getPageView(page)
      if (!isEmpty(pdfPage)) {
        const polygonContainer = pdfPage.div.querySelector(`.polygon-container.${fieldName}`)

        if (polygonContainer) {
          const screenPosition = polygonContainer.getBoundingClientRect()
          const scrollableElement = pdfViewerApplication.pdfViewer.container
          let offsetTop = screenPosition.top - 100 + scrollableElement.scrollTop

          // account for the height of the footer
          const footer = document.getElementById("full-screen-footer")
          if (footer) {
            offsetTop -= footer.getBoundingClientRect().height

            // account for polygon being on a later page
            if (pdfPage > 0) {
              offsetTop *= pdfPage
            }
          }
          scrollableElement.scrollTo({ top: offsetTop, behavior: "smooth" })
        }
      }
    } else {
      const image = document.getElementById("overlay")
      if (!image) return
      const polygonContainer = image.querySelector(`.polygon-container.${fieldName}`)
      if (polygonContainer) {
        const screenPosition = polygonContainer.getBoundingClientRect()
        const scrollableElement = document.getElementById("document-overflow-container")
        let offsetTop = screenPosition.top - 100 + image.scrollTop

        // account for the height of the footer
        const footer = document.getElementById("full-screen-footer")
        if (footer) {
          offsetTop -= footer.getBoundingClientRect().height
        }

        scrollableElement.scrollTo({ top: offsetTop, behavior: "smooth" })
      }
    }
  }

  setupPdfjsEventListener() {
    const iframe = document.getElementById("pdf-viewer")
    if (!iframe) return

    iframe.addEventListener("load", () => {
      // Access the iframe's window and document
      const iframeWindow = iframe.contentWindow
      const maxRetries = 10
      let attempts = 0

      const checkPdfViewerApplication = () => {
        attempts++
        if (iframeWindow.PDFViewerApplication) {
          this.addPdfjsEventListeners(iframeWindow.PDFViewerApplication)
        } else if (attempts < maxRetries) {
          console.error("PDFViewerApplication is not available. Retrying...")
          setTimeout(checkPdfViewerApplication, 100)
        } else {
          console.error("Failed to load PDFViewerApplication when the max retries have reached.")
        }
      }

      checkPdfViewerApplication()
    })
  }

  addPdfjsEventListeners(pdfViewerApplication) {
    const maxRetries = 10
    let attempts = 0

    const checkEventBus = () => {
      attempts++
      if (pdfViewerApplication.eventBus) {
        pdfViewerApplication.eventBus.on("pagerendered", (event) => {
          const pageNumber = event.pageNumber
          this.setupPdfHighlights(pdfViewerApplication, pageNumber)
        })
      } else if (attempts < maxRetries) {
        console.error("eventBus is not available. Retrying...")
        setTimeout(checkEventBus, 100)
      } else {
        console.error("Failed to load eventBus when the max retries have reached.")
      }
    }
    checkEventBus()
  }

  setupPdfHighlights(pdfViewerApplication, pageNumber) {
    // Access the specific page element
    const page = pdfViewerApplication.pdfViewer.getPageView(pageNumber - 1).div
    const pdfWidth = page.clientWidth
    const pdfHeight = page.clientHeight

    // Filter polygons for the current page
    const polygonsForCurrentPage = this.boundingPolysValue.filter((poly) => poly.page === pageNumber - 1)

    // Draw polygons
    polygonsForCurrentPage.forEach((poly) => {
      const boundingPoly = poly.normalizedVertices
      const polygonContainer = document.createElement("div")
      polygonContainer.className = `polygon-container ${poly.fieldName}`
      polygonContainer.style.position = "absolute"
      polygonContainer.style.left = `${boundingPoly[0].x * pdfWidth}px`
      polygonContainer.style.top = `${boundingPoly[0].y * pdfHeight}px`
      polygonContainer.style.width = `${(boundingPoly[1].x - boundingPoly[0].x) * pdfWidth}px`
      polygonContainer.style.height = `${(boundingPoly[2].y - boundingPoly[0].y) * pdfHeight}px`
      polygonContainer.style.zIndex = "1000"

      page.appendChild(polygonContainer)

      polygonContainer.addEventListener("mouseenter", () => {
        polygonContainer.classList.add("hovered")
      })

      polygonContainer.addEventListener("mouseleave", () => {
        polygonContainer.classList.remove("hovered")
      })
    })
  }

  private getFocusField(fieldId): HTMLElement {
    return document.getElementById(fieldId).closest(".input-highlight")
  }
}
