import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ai--global-assistant-panels"
export default class extends Controller {
  static targets = ["thread", "greetings", "suggestions", "landingSuggestions", "history", "tips", "container"]

  static values = {
    initialPanel: { type: String, default: "landingSuggestions" },
  }

  threadTarget: HTMLElement
  greetingsTarget: HTMLDivElement
  suggestionsTarget: HTMLElement
  landingSuggestionsTarget: HTMLDivElement
  historyTarget: HTMLElement
  tipsTarget: HTMLElement
  containerTarget: HTMLElement

  initialPanelValue: string

  panels: Map<HTMLElement, string>
  positions: Map<HTMLElement, number>
  activePanel: HTMLElement

  connect() {
    this.panels = new Map([
      [this.threadTarget, "thread"],
      [this.greetingsTarget, "greetings"],
      [this.suggestionsTarget, "suggestions"],
      [this.landingSuggestionsTarget, "landingSuggestions"],
      [this.historyTarget, "history"],
      [this.tipsTarget, "tips"],
    ])

    this.positions = new Map([
      [this.threadTarget, 0],
      [this.greetingsTarget, 0],
      [this.suggestionsTarget, 0],
      [this.landingSuggestionsTarget, 0],
      [this.historyTarget, 0],
      [this.tipsTarget, 0],
    ])

    this.showInitialPanel()
  }

  showInitialPanel = () => {
    const initialPanel = Array.from(this.panels.entries()).find(([_, id]) => id === this.initialPanelValue)

    if (initialPanel) {
      this.togglePanel(initialPanel[0])
    } else {
      this.togglePanel(this.landingSuggestionsTarget)
    }
  }

  togglePanel = (panel: HTMLElement) => {
    this.positions.set(this.activePanel, this.containerTarget.scrollTop)
    this.activePanel = panel

    this.panels.forEach((id, p) => {
      if (p === panel) {
        p.classList.remove("hidden")
        this.dispatch("toggle-panel", { detail: { panel: id } })
      } else {
        p.classList.add("hidden")
      }
    })

    this.containerTarget.scrollTop = this.positions.get(panel)
  }

  // Triggered from ai--global-assistant:submit
  showThread = () => {
    this.togglePanel(this.threadTarget)
  }

  // Triggered from ai--global-assistant:message-input
  toggleGreetings = (event) => {
    if (this.hasActiveThread()) return

    const content = event.detail?.content?.trim() || ""
    const greetingsActive = this.activePanel === this.greetingsTarget

    if (content.length > 0) {
      if (!greetingsActive) this.togglePanel(this.greetingsTarget)
    } else {
      this.toggleThread()
    }
  }

  toggleThread = () => {
    if (this.hasActiveThread()) {
      this.togglePanel(this.threadTarget)
    } else {
      this.togglePanel(this.landingSuggestionsTarget)
    }
  }

  toggleHistory = () => {
    this.togglePanel(this.historyTarget)
  }

  toggleTips = () => {
    this.togglePanel(this.tipsTarget)
  }

  toggleSuggestions = (e) => {
    if (this.hasActiveThread()) {
      this.togglePanel(this.suggestionsTarget)
    } else {
      e.preventDefault()
      return
    }
  }

  hasActiveThread = () => {
    return this.threadTarget.querySelectorAll('turbo-frame[data-thread="message"]').length > 0
  }
}
